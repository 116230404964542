import { useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProfilePage } from '@Components';
import { PathParams } from '@App/paths';
import { useRouter } from '@Helpers/useRouter';
import * as topLoader from '@Helpers/topLoader';
import { useUserContext } from '@Contexts';
import {
  usePublicGroupProfile,
  useProjectsList,
  useGetFundsByGroup,
  useGetGroupProjectUpdates,
} from '@Hooks/Api';
import { GroupProfileData } from '@Types';
import router from 'next/router';
import { useGetCurrentTab } from '@Hooks/useGetCurrentTab';

interface GroupProfilePageControllerProps {
  initialGroupProfileData: GroupProfileData;
}

export const GroupProfilePageController = ({
  initialGroupProfileData,
}: GroupProfilePageControllerProps) => {
  const { t } = useTranslation('profilePage');
  const { params } = useRouter();
  const organizationId = Number(params[PathParams.ORGANIZATION_ID]);
  const [getGroupProfileData, { data }] = usePublicGroupProfile();
  const { userData } = useUserContext();
  const [getFundsByGroup, { data: projectsFundsList, loading: isProjectsFundsListLoading }] =
    useGetFundsByGroup();
  const [getGroupProjectUpdates, { paginatedData: projectUpdatesPaginatedData }] =
    useGetGroupProjectUpdates();
  const { loading: isProjectsUpdatesListLoading, totalCount: totalProjectUpdatesCount } =
    projectUpdatesPaginatedData;
  const [fetchProjectsList, { paginatedData: projectsListPaginatedResponse }] = useProjectsList();
  const { page: projectsList, loading: isProjectsListLoading } = projectsListPaginatedResponse;

  useEffect(() => {
    if (userData?.user_type) {
      topLoader.start();
      getGroupProfileData(organizationId).finally(() => {
        topLoader.done();
      });
    }
  }, [getGroupProfileData, organizationId, userData?.user_type]);

  useEffect(() => {
    fetchProjectsList(organizationId);
  }, [fetchProjectsList, organizationId]);

  useEffect(() => {
    getFundsByGroup(organizationId);
  }, [getFundsByGroup, organizationId]);

  useEffect(() => {
    getGroupProjectUpdates(organizationId);
  }, [getGroupProjectUpdates, organizationId]);

  const {
    name,
    avatar_thumbnail,
    description,
    type,
    charity_or_company_number,
    set_up_year,
    legal_structure,
    paid_staff_number,
    volunteers_number,
    member_name_1,
    member_email_1,
    member_name_2,
    member_email_2,
    categories,
    location,
    city,
    phone_number,
    address1,
    address2,
    address3,
    county,
    postcode,
    website_url,
    facebook_url,
    linkedin_url,
    instagram_url,
    twitter_url,
  } = data || initialGroupProfileData;

  const tabs = useMemo(
    () => [
      { title: t('group_page_tabs.about'), slug: 'about' },
      {
        title: t('group_page_tabs.projects', {
          projectsCount: isProjectsListLoading ? '...' : projectsList.length,
        }),
        slug: 'projects',
      },
      {
        title: t('group_page_tabs.funds', {
          fundsCount: isProjectsFundsListLoading ? '...' : projectsFundsList.length,
        }),
        slug: 'funds',
      },
      {
        title: t('group_page_tabs.project_updates', {
          projectUpdatesCount: isProjectsUpdatesListLoading ? '...' : totalProjectUpdatesCount,
        }),
        slug: 'updates',
      },
    ],
    [
      isProjectsFundsListLoading,
      isProjectsListLoading,
      isProjectsUpdatesListLoading,
      projectsFundsList.length,
      projectsList.length,
      totalProjectUpdatesCount,
      t,
    ],
  );

  // Set tab based on router and update modal state
  let currentTab = useGetCurrentTab(params.groupProfileSlug?.[0], tabs);

  const setTab = useCallback(
    (tabNumber: number) => {
      return router.push(`/group-profile/${organizationId}/${tabs[tabNumber].slug}`, undefined, {
        shallow: true,
      });
    },
    [organizationId, tabs],
  );

  return (
    <ProfilePage
      isGroup
      name={name}
      avatar={avatar_thumbnail}
      tabs={tabs}
      currentTab={currentTab}
      setCurrentTab={setTab}
      canModifyOrganizationProfile={Boolean(userData?.can_modify_organization_profile)}
      aboutSection={{
        description: description,
        organizationType: t(`common:group_types.${type}`),
        organizationNumber: charity_or_company_number,
        setupYear: set_up_year,
        legalStructure:
          legal_structure &&
          t(`createAccount:create_profile_common.organization_structure.${legal_structure}`),
        staffNumber: paid_staff_number,
        volunteersNumber: volunteers_number,
        memberName1: member_name_1,
        memberEmail1: member_email_1,
        memberName2: member_name_2,
        memberEmail2: member_email_2,
        categoriesIds: categories,
      }}
      linksTile={{
        orgWebsiteUrl: website_url,
        orgFacebookUrl: facebook_url,
        orgInstagramUrl: instagram_url,
        orgTwitterUrl: twitter_url,
        orgLinkedinUrl: linkedin_url,
      }}
      locationTile={{
        coordinates: location.coordinates
          ? [Number(location.coordinates[1]), Number(location.coordinates[0])]
          : undefined,
        city: city,
        phoneNumber: phone_number,
        address1: address1,
        address2: address2,
        address3: address3,
        county: county,
        postcode: postcode,
      }}
      projectsFundsList={{
        isProjectsFundsListLoading,
        projectsFundsList,
      }}
      projectsListSection={{
        projectsPaginatedResponse: projectsListPaginatedResponse,
      }}
      projectUpdatesSection={{
        projectUpdatesPaginatedData: projectUpdatesPaginatedData,
      }}
      totalProjectUpdatesCount={totalProjectUpdatesCount}
      progress={userData?.progress ?? 0}
      isCurrentProfile={organizationId === userData?.organization_id}
    />
  );
};
