import { useTranslation } from 'react-i18next';

import { Button } from '@Components/common';

import { Organization } from '@Types';
import { ContentModal } from '@Components';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import styles from './DuplicateOrganizationWarningModal.module.scss';
import { Routes } from '@App/paths';
import { SUPPORT_EMAIL } from '@Helpers/constants';
import { formatMailto } from '@Helpers/formatMailto';
import { RiMailLine } from 'react-icons/ri';

interface DuplicateOrganizationWarningModalProps {
  organizations: Organization[];
  submit: () => any;
  close: () => any;
}

export const DuplicateOrganizationWarningModal = ({
  organizations,
  submit,
  close,
}: DuplicateOrganizationWarningModalProps) => {
  const { t } = useTranslation('createAccount');
  const getProfileUrl = (organization: Organization) => {
    return organization.profile && Routes.GROUP_PROFILE_PAGE_ABOUT_TAB(organization.id);
  };
  const supportLink = formatMailto({ emailAddress: SUPPORT_EMAIL });
  return (
    <ContentModal isOpen={organizations.length > 0} onClose={close} wide>
      <div className={styles.container}>
        <h3>{t('signing.duplicate_organization_modal.title')}</h3>
        <p>{t('signing.duplicate_organization_modal.text_1')}</p>
        <p>{t('signing.duplicate_organization_modal.text_2')}</p>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableHead}>
                {t('signing.duplicate_organization_modal.name')}
              </TableCell>
              <TableCell className={styles.tableHead}>
                {t('signing.duplicate_organization_modal.city')}
              </TableCell>
              <TableCell className={styles.tableHead}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((organization) => (
              <TableRow key={organization.id}>
                <TableCell>
                  <Button
                    className={styles.linkButton}
                    buttonType="text"
                    href={getProfileUrl(organization)}
                  >
                    {organization.name}
                  </Button>
                </TableCell>
                <TableCell>{organization.profile?.city || ''}</TableCell>
                <TableCell>
                  <Button
                    buttonType="tertiary"
                    startIcon={<RiMailLine />}
                    href={formatMailto({
                      emailAddress: SUPPORT_EMAIL,
                      subject: `Request to join organisation: ${organization.name}`,
                    })}
                  >
                    {t('signing.duplicate_organization_modal.contact')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={styles.buttonRow}>
          <Button buttonType="primary" className={styles.button} onClick={close}>
            <p>{t('signing.duplicate_organization_modal.cancel_button')}</p>
          </Button>
          <Button buttonType="danger" className={styles.button} onClick={submit}>
            <p>{t('signing.duplicate_organization_modal.continue_button')}</p>
          </Button>
        </div>
        <p className={styles.bottomText}>
          {t('signing.duplicate_organization_modal.bottom_text')}{' '}
          <Button className={styles.linkButton} buttonType="text" to={supportLink}>
            {t('signing.duplicate_organization_modal.contact_us')}
          </Button>
        </p>
      </div>
    </ContentModal>
  );
};
