import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { FunderSurveyReportSummaryData, GrandchildrenOptionsEnum } from '@Types';
import { useCallback } from 'react';

export const useGetFunderReportSummaryData = () => {
  const [fetch, response] = useRequest<FunderSurveyReportSummaryData>();

  const getFunderReportSummaryData = useCallback(
    (
      organizationId: number,
      include_grandchildren?: GrandchildrenOptionsEnum,
      org_ids?: number[],
    ) =>
      fetch(
        API.paths.funder_report_summary_data(
          organizationId,
          include_grandchildren ? include_grandchildren : GrandchildrenOptionsEnum.NONE,
          org_ids ? org_ids : [],
        ),
        'get',
      ),

    [fetch],
  );

  return [getFunderReportSummaryData, response] as const;
};
